import gql from 'graphql-tag'


const ServiceContainerBlockFragment = gql`
    fragment ServiceContainerBlockFragment on BlockType{
      ... on ServiceContainerBlock {
          text
          services {
              __typename,
              logo
              title
          }
      }
    }
`

export default ServiceContainerBlockFragment
