import gql from 'graphql-tag'


const CountdownBlockFragment = gql`
    fragment CountdownBlockFragment on BlockType {
      ... on CountdownBlock {
          text
          date
          image
          copyright
          cta_link
          cta_title
      }
    }
`

export default CountdownBlockFragment
