import gql from 'graphql-tag'


const DirectoryPublicBlockFragment = gql`
    fragment DirectoryPublicBlockFragment on BlockType {
      ... on DirectoryPublicBlock {
          title
          introduction
          cta_title
          linkable {
              __typename
              ... on Route {
                  name
                  slug
              }
              ... on Page {
                  title
                  full_slug
              }
          }
          url
          text_before_title_texts
          first_title_text_container {
              title_texts {
                  title
                  text
              }
          }
          text_between_title_texts
          second_title_text_container {
              title_texts {
                  title
                  text
              }
          }
          text_after_title_texts
      }
    }
`

export default DirectoryPublicBlockFragment
