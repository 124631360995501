import gql from 'graphql-tag'


export const GetXp = gql`
    query GetXp {
        me {
            degree_level
            certifications{
                id
                title
                establishment
                year
            }
            degrees {
                id
                title
                school
                year
            }
            jobs{
                id
                title
                company
                description
                contract
                start_at
                end_at
            }
        }
    }
`
