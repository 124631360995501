import gql from 'graphql-tag'


const ArticleTransverseContainerBlockFragment = gql`
    fragment ArticleTransverseContainerBlockFragment on BlockType {
      ... on ArticleTransverseContainerBlock {
          title
          cta_link
          cta_title
          articles {
            title
            description
            picture
            slug
            category {
              name
              theme
              bgColor
            }
          }
      }
    }
`

export default ArticleTransverseContainerBlockFragment
