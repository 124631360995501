import gql from 'graphql-tag'


const OnBoardingBlockFragment = gql`
    fragment OnBoardingBlockFragment on BlockType{
        ... on OnBoardingBlock {
          intro_candidate
          promise_candidate
          intro_company
          promise_company
      }
    }
`

export default OnBoardingBlockFragment
