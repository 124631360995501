import { all, call, put, select } from 'redux-saga/effects'

import ApiSagas from '../api/sagas'
import userQueries from '../../graphql/query/User/index'
import api from '../../config/api'
import app from '../../config/app'
import { selectors as AppSelectors, pageType } from '../app/redux'

import { actions } from './redux'


export default class LinkedinSagas {

  static* init(ctx) {
    const csrf = ctx.query?.state
    const code = ctx.query?.code

    const pageSelector = yield select(AppSelectors.page)
    const redirectUri = `${app.APP_URL}/${pageSelector(pageType.ON_BOARDING).link}`

    if (code && csrf === api.LINKEDIN_STATE) {
      const result = yield call(ApiSagas.query, userQueries.linkedInProfile, { code, redirectUri })

      const transformedData = {
        firstName: result?.data?.linkedInProfile?.first_name,
        lastName: result?.data?.linkedInProfile?.last_name,
        email: result?.data?.linkedInProfile?.email,
      }

      yield put(actions.setData(transformedData))
    }
  }

  static* loop() {
    yield all([
      //
    ])
  }

}
