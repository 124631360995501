import gql from 'graphql-tag'


export const TimesStampsFragment = {
  fragment: gql`
    fragment timesStampsFields on TimesStamps {
      created_at,
      updated_at
    }
  `,
}
