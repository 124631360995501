import gql from 'graphql-tag'


const LastOffersBlockFragment = gql`
    fragment LastOffersBlockFragment on BlockType {
        ... on LastOffersBlock {
            title
            text
            offers {
                ...offerCard
            }
        }
    }
`

export default LastOffersBlockFragment
