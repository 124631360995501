const filtersSlugTypes = [
  'type',
  'date',
]

const getFilterSlugRightField = {
  type: 'type',
  date: 'date',
}

export const regexEventsFilters = new RegExp(`(?:\\/(?:(?:(${filtersSlugTypes.join('|')})-([\\w\\d-]+))))`, 'g')

export const transformEventsSlugFilters = (asPath) => {
  let matches
  const slugFilters = {}

  while ((matches = regexEventsFilters.exec(asPath)) !== null) {
    const property = getFilterSlugRightField[matches[1]]

    if (!Object.prototype.hasOwnProperty.call(slugFilters, property)) {
      slugFilters[property] = matches[2]
    } else {
      if (!Array.isArray(slugFilters[property])) {
        slugFilters[property] = [slugFilters[property]]
      }
      slugFilters[property].push(matches[2])
    }
  }
  return slugFilters
}
