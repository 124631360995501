import gql from 'graphql-tag'


export const MarkNotificationAsRead = gql`
    mutation markNotificationAsRead($id: ID!) {
        markNotificationAsRead(id: $id) {
            message
        }
    }
`
