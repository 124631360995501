import gql from 'graphql-tag'


const FaqBlockFragment = gql`
    fragment FaqBlockFragment on BlockType {
      ... on FaqBlock {
          title
          faq_entries {
            title
            text
          }
      }
    }
`

export default FaqBlockFragment
