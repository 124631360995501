import gql from 'graphql-tag'


export const UpdateSkills = gql`
    mutation updateProfessionalInformation(
        $id: ID!,
        $input: UpdateProfessionalInformationInput!
    ) {
        updateProfessionalInformation(
            id: $id,
            input: $input
        ) {
            title
            experience
            qualities
            sector {
                slug
            }
            skills {
                id
            }
        }
    }
`
