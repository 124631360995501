import gql from 'graphql-tag'


const CtaBlockFragment = gql`
    fragment CtaBlockFragment on BlockType{
      ... on CtaBlock {
          text
          title
          cta_title
          url
          linkable {
              ... on Route {
                  key
                  name
                  slug
              }
              ... on Page {
                  title
                  slug
                  full_slug
              }
          }
      }
    }
`

export default CtaBlockFragment
