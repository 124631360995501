import gql from 'graphql-tag'


export const PaginationFragment = {
  fragment: gql`
    fragment pagination on PaginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
    }
  `,
}
