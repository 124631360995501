import gql from 'graphql-tag'


const ImageBlockFragment = gql`
    fragment ImageBlockFragment on BlockType{
      ... on ImageBlock {
          image
          image_link
          routable {
              __typename
              ... on Route {
                  key
                  name
                  slug
              }
              ... on Page {
                  title
                  slug
                  full_slug
              }
          }
      }
    }
`

export default ImageBlockFragment
