import gql from 'graphql-tag'


export const usersByAutocomplete = gql`
  query usersByAutocomplete($query: String!, $onlyPremium: Boolean, $first: Int!, $page: Int){
    usersByAutocomplete(
      query: $query
      onlyPremium: $onlyPremium
      first: $first
      page: $page
    ) {
      data {
        id
        first_name
        last_name
        avatar
        company {
          name
        }
      }
      paginatorInfo {
        count
        currentPage
        total
      }
    }
  }
`
