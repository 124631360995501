import gql from 'graphql-tag'


const ParagraphBlockFragment = gql`
    fragment ParagraphBlockFragment on BlockType{
      ... on ParagraphBlock {
          text
      }
    }
`

export default ParagraphBlockFragment
