import gql from 'graphql-tag'


const VideoBlockFragment = gql`
    fragment VideoBlockFragment on BlockType{
      ... on VideoBlock {
        video
      }
    }
`

export default VideoBlockFragment
