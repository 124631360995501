import gql from 'graphql-tag'


export const UpdateXp = gql`
    mutation updateExperienceInformation($id: ID!,
        $certifications: UpdateCertificationRelation,
        $degrees: UpdateDegreeRelation,
        $jobs: UpdateJobRelation,
        $degree_level: DegreeLevel,
    ) {
        updateExperienceInformation(
            id: $id,
            certifications: $certifications,
            degrees: $degrees,
            jobs: $jobs,
            degree_level: $degree_level
        ) {
            degree_level
            certifications{
                id
                title
                establishment
                year
            }
            degrees {
                id
                title
                school
                year
            }
            jobs {
                id
                title
                company
                description
                contract
                start_at
                end_at
            }
        }
    }
`
