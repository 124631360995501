import gql from 'graphql-tag'


const PartnerContainerBlockFragment = gql`
    fragment PartnerContainerBlockFragment on BlockType{
      ... on PartnerContainerBlock {
          title
          partners(first: 30) {
              data {
                  id
                  name
                  logo
                  url
              }
          }
      }
    }
`

export default PartnerContainerBlockFragment
