import gql from 'graphql-tag'


export const deleteAccount = gql`
    mutation deleteAccount{
        deleteAccount{
            message
        }
    }
`
