import gql from 'graphql-tag'


const ArgumentsBlockFragment = gql`
    fragment ArgumentsBlockFragment on BlockType {
      ... on ArgumentsBlock {
          cta_title
          cta_link
          arguments {
            title
            text
            image
            image_position
          }
      }
    }
`

export default ArgumentsBlockFragment
