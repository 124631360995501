import gql from 'graphql-tag'

// Fragments
import { TimesStampsFragment } from '../Fragments/TimesStampsFragment'
import { Blocks } from '../../../blocks/fragments'
import { ShareFragment } from '../Fragments/ShareFragment'


export const PageByFullSlug = gql`    
    query PageByFullSlug(
        $slug: String!,
        $page: Int,
        $order:[OrderByClause!],
        $offerFilters: OfferFilterClause,
        $companyFilters: CompanyFilterClause,
        $profileFilters: ProfileFilterClause,
        $eventFilters: EventFilterClause,
        $postFilters: PostFilterClause,
        $relationFilters: RelationMatchFilterClause,
        $articleFilters: ArticleFilterClause,
        $directoryType: DirectoryType!
    ) {
        pageByFullSlug(slug: $slug) {
            id
            title
            slug
            full_slug
            has_header
            header_title
            header_subtitle
            header_image
            header_type
            header_cta_label
            header_cta_url
            header_cta_type
            has_search_bar
            search_bar_title
            search_bar_type
            search_bar_stat
            breadcrumb {
                title
                is_current
                link
            }
            stat {
                offers_count
                candidates_count
                companies_count
            }
            share {
                ...share
            }
            ...blocks,
            ...timesStampsFields
        }
    }

    # Fragments
    ${Blocks}
    ${ShareFragment.fragment}
    ${TimesStampsFragment.fragment}
`
