import gql from 'graphql-tag'


const TopCompaniesListBlockFragment = gql`
    fragment TopCompaniesListBlockFragment on BlockType{
      ... on TopCompaniesListBlock {
          title
          cta_title
          topCompanies(first: 10) {
              data {
                  name
                  full_slug
                  offers_count
                  logo
                  location {
                      city
                  }
              }
          }
      }
    }
`

export default TopCompaniesListBlockFragment
