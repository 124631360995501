import gql from 'graphql-tag'


export const PostCardFragment = {
  fragment: gql`
      fragment postCard on Post {
          id
          slug
          type
          title
          description
          picture
          link
          link_label
          published_at
          is_published
          is_ogcn
          files {
              id
              preview_url
              pdf_url
              title
          }
          user {
              slug
              first_name
              last_name
              company {
                  name
                  logo
              }
          }
      }
  `,
}
