import gql from 'graphql-tag'


const OffersBlockFragment = gql`
    fragment OffersBlockFragment on BlockType {
      ... on OffersBlock {
        offers_title
        offers_text
        offers_table_heading
        offers_sections {
          title
          arguments {
            title
            type
          }
        }
        offers_cta_label
        premium_price
      }
    }
`

export default OffersBlockFragment
