import gql from 'graphql-tag'


const PostContainerBlockFragment = gql`
    fragment PostContainerBlockFragment on BlockType{
      ... on PostContainerBlock {
          text
          postsByFilters(first: 10, filters: $postFilters, page: $page) {
              paginatorInfo {
                  ...pagination
              }
              data {
                  ...postCard
              }
          }
      }
    }
`

export default PostContainerBlockFragment
