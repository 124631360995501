import gql from 'graphql-tag'


export const GetSkills = gql`
    query GetSkills {
        me {
            title
            experience
            qualities
            sector {
                slug
            }
            skills {
                id
            }
        }
    }
`
