import gql from 'graphql-tag'


const RelationHeroBlockFragment = gql`
    fragment RelationHeroBlockFragment on BlockType {
        ... on RelationHeroBlock {
            title
            text
            img: image
            routable {
                __typename
                ... on Route {
                    name
                    slug
                }
                ... on Page {
                    title
                    full_slug
                }
            }
        }
    }
`

export default RelationHeroBlockFragment
