import gql from 'graphql-tag'

import { OfferCardFragment } from '../Fragments/OfferCardFragment'


export const GetCandidateOpportunities = gql`
    query GetCandidateOpportunities {
        me {
            savedOffers (first: 500) {
                data {
                    ...offerCard 
                }
            }
            candidacies (first: 500) {
                data {
                    status
                    applied_at
                    offer {
                        ...offerCard
                    }
                }
            }
            contacts (first: 500) {
                data {
                    status
                    offer {
                        ...offerCard
                    }
                }
            }
        }
    }

    ${OfferCardFragment.fragment}
`
