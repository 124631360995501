import gql from 'graphql-tag'

import { EventCardFragment } from '../../graphql/query/Fragments/EventCardFragment'
import LastOffersBlockFragment from '../LastOffersBlock/fragment'


const HomePageBlockFragment = gql`
    fragment HomePageBlockFragment on BlockType{
      ... on HomePageBlock {
          registerBanner
          registerTitle
          registerCtaLabel
          registerCtaLinkable {
              ... on Route {
                  key
                  name
                  slug
              }
              ... on Page {
                  title
                  slug
                  full_slug
              }
          }
          registerCtaUrl
          membershipTitle
          membershipText
          membershipEntries{
              title
              text
          }
          howToTitle
          howToEntries {
              logo
              text
          }
          eventsTitle
          eventsEntries(first:4){
              data{
                  ... eventCard   
              }
          }
          eventsCtaLabel
          eventsCtaLinkable {
              ... on Route {
                  key
                  name
                  slug
              }
              ... on Page {
                  title
                  slug
                  full_slug
              }
          }
          eventsCtaUrl
          offersTitle
          offersText
          offersCtaLabel
          offersCtaLinkable {
              ... on Route {
                  key
                  name
                  slug
              }
              ... on Page {
                  slug
                  full_slug
              }
          }
          offersCtaUrl
          premiumPrice
          discount
          manifestLabel
          manifestLink {
              slug
              full_slug
          }
          lastOffers{
              ... LastOffersBlockFragment
          }
      }
    }
    
  ${EventCardFragment.fragment}
  ${LastOffersBlockFragment}
`

export default HomePageBlockFragment
