import gql from 'graphql-tag'


const EventListBlockFragment = gql`
    fragment EventListBlockFragment on BlockType {
      ... on EventListBlock {
        eventsByFilters(first: 12, orderBy: $order, filters: $eventFilters, page: $page) {
          paginatorInfo {
            ...pagination
          }
          data {
            ...eventCard
          }
        }
        pushPremium {
            order
        }
      }
    }
`

export default EventListBlockFragment
