import gql from 'graphql-tag'


const EventPublicBlockFragment = gql`
    fragment EventPublicBlockFragment on BlockType {
      ... on EventPublicBlock {
          title
          introduction
          cta_title
          linkable {
              __typename
              ... on Route {
                  name
                  slug
              }
              ... on Page {
                  title
                  full_slug
              }
          }
          url
          text_before_title_texts
          title_text_container {
              title_texts {
                  title
                  text
              }
          }
          text_after_title_texts
      }
    }
`

export default EventPublicBlockFragment
