import gql from 'graphql-tag'


const RegisterBlockFragment = gql`
    fragment RegisterBlockFragment on BlockType{
      ... on RegisterBlock {
          cta_title
          profiles {
              logo
              title
              text
          }
      }
    }
`

export default RegisterBlockFragment
