/* eslint-disable max-lines */
import gql from 'graphql-tag'

// Fragments
import { EventCardFragment } from '../graphql/query/Fragments/EventCardFragment'
import { OfferCardFragment } from '../graphql/query/Fragments/OfferCardFragment'
import { PaginationFragment } from '../graphql/query/Fragments/PaginationFragment'
import { PostCardFragment } from '../graphql/query/Fragments/PostCardFragment'

import ArgumentsBlockFragment from './ArgumentsBlock/fragment'
import ArticleContainerBlockFragment from './ArticleContainerBlock/fragment'
import ArticleTransverseContainerBlockFragment from './ArticleTransverseContainerBlock/fragment'
import CountdownBlockFragment from './CountdownBlock/fragment'
import CtaBlockFragment from './CtaBlock/fragment'
import DirectoryBlockFragment from './DirectoryBlock/fragment'
import DirectoryPublicBlockFragment from './DirectoryPublicBlock/fragment'
import EventListBlockFragment from './EventListBlock/fragment'
import EventPublicBlockFragment from './EventPublicBlock/fragment'
import FaqBlockFragment from './FaqBlock/fragment'
import FinishingOffersBlockFragment from './FinishingOffersBlock/fragment'
import HighlightedEventBlockFragment from './HighlightedEventBlock/fragment'
import HomePageBlockFragment from './HomePageBlock/fragment'
import ImageBlockFragment from './ImageBlock/fragment'
import LastOffersBlockFragment from './LastOffersBlock/fragment'
import OfferListBlockFragment from './OfferListBlock/fragment'
import OffersBlockFragment from './OffersBlock/fragment'
import OfferPublicBlockFragment from './OfferPublicBlock/fragment'
import OnBoardingBlockFragment from './OnBoardingBlock/fragment'
import ParagraphBlockFragment from './ParagraphBlock/fragment'
import PartnerContainerBlockFragment from './PartnerContainerBlock/fragment'
import PostContainerBlockFragment from './PostContainerBlock/fragment'
import PostPublicBlockFragment from './PostPublicBlock/fragment'
import PushContentBlockFragment from './PushContentBlock/fragment'
import PushRegisterBlockFragment from './PushRegisterBlock/fragment'
import RegisterBlockFragment from './RegisterBlock/fragment'
import ServiceContainerBlockFragment from './ServiceContainerBlock/fragment'
import TestimonyContainerBlockFragment from './TestimonyContainerBlock/fragment'
import TopCompaniesListBlockFragment from './TopCompaniesListBlock/fragment'
import RelationHeroBlockFragment from './RelationHeroBlock/fragment'
import RelationMatchListBlockFragment from './RelationMatchListBlock/fragment'
import VideoBlockFragment from './VideoBlock/fragment'


export const Blocks = gql`
    fragment blocks on Blockable{
        blocks {
            __typename,
            ... ArgumentsBlockFragment
            ... ArticleContainerBlockFragment
            ... ArticleTransverseContainerBlockFragment
            ... CtaBlockFragment
            ... CountdownBlockFragment
            ... DirectoryBlockFragment
            ... DirectoryPublicBlockFragment
            ... EventListBlockFragment
            ... EventPublicBlockFragment
            ... FaqBlockFragment
            ... FinishingOffersBlockFragment
            ... HighlightedEventBlockFragment
            ... HomePageBlockFragment
            ... ImageBlockFragment
            ... LastOffersBlockFragment
            ... OfferListBlockFragment
            ... OffersBlockFragment
            ... OfferPublicBlockFragment
            ... OnBoardingBlockFragment
            ... ParagraphBlockFragment
            ... PartnerContainerBlockFragment
            ... PostContainerBlockFragment
            ... PostPublicBlockFragment
            ... PushContentBlockFragment
            ... PushRegisterBlockFragment
            ... RegisterBlockFragment
            ... ServiceContainerBlockFragment
            ... TestimonyContainerBlockFragment
            ... TopCompaniesListBlockFragment
            ... RelationHeroBlockFragment
            ... RelationMatchListBlockFragment
            ... VideoBlockFragment
        }
    }

    # Fragments
    ${EventCardFragment.fragment}
    ${OfferCardFragment.fragment}
    ${PaginationFragment.fragment}
    ${PostCardFragment.fragment}
    
    # Blocks
    ${ArgumentsBlockFragment}
    ${ArticleContainerBlockFragment}
    ${ArticleTransverseContainerBlockFragment}
    ${CountdownBlockFragment}
    ${CtaBlockFragment}
    ${DirectoryBlockFragment}
    ${DirectoryPublicBlockFragment}
    ${EventListBlockFragment}
    ${EventPublicBlockFragment}
    ${FaqBlockFragment}
    ${FinishingOffersBlockFragment}
    ${HighlightedEventBlockFragment}
    ${HomePageBlockFragment}
    ${ImageBlockFragment}
    ${LastOffersBlockFragment}
    ${OfferListBlockFragment}
    ${OffersBlockFragment}
    ${OfferPublicBlockFragment}
    ${OnBoardingBlockFragment}
    ${ParagraphBlockFragment}
    ${PartnerContainerBlockFragment}
    ${PostContainerBlockFragment}
    ${PostPublicBlockFragment}
    ${PushContentBlockFragment}
    ${PushRegisterBlockFragment}
    ${RegisterBlockFragment}
    ${ServiceContainerBlockFragment}
    ${TestimonyContainerBlockFragment}
    ${TopCompaniesListBlockFragment}
    ${RelationHeroBlockFragment}
    ${RelationMatchListBlockFragment}
    ${VideoBlockFragment}
`
