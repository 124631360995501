import gql from 'graphql-tag'


const PushRegisterBlockFragment = gql`
    fragment PushRegisterBlockFragment on BlockType{
      ... on PushRegisterBlock {
          title
          text
          cta_title
      }
    }
`

export default PushRegisterBlockFragment
