import gql from 'graphql-tag'


const RelationMatchListBlockFragment = gql`
  fragment RelationMatchListBlockFragment on BlockType {
    ... on RelationMatchListBlock {
      relationMatchByFilters(first: 9, orderBy: $order, filters: $relationFilters, page: $page) {
        paginatorInfo {
          ...pagination
        }
        data {
          id
          avatar
          first_name
          last_name
          percentage
          company {
            id
            name
          }
          companyFunction {
            id
            name
          }
          domains {
            id
            name
            sector {
              id
              name
              slug
              bloc_image
            }
          }
        }
      }
    }
  }
`

export default RelationMatchListBlockFragment
