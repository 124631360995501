import gql from 'graphql-tag'


export const GetAlerts = gql`
    query GetAlerts {
        me {
            notifications {
                id
                type
                message
                payload
                offer_id
            }
        }
    }
`
