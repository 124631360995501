import gql from 'graphql-tag'


const FinishingOffersBlockFragment = gql`
    fragment FinishingOffersBlockFragment on BlockType {
        ... on FinishingOffersBlock {
            title
            offers {
                ...offerCard
            }
        }
    }
`

export default FinishingOffersBlockFragment
