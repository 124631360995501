/* eslint-disable max-lines */
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select } from 'redux-saga/effects'

import { bannerTypes } from '../../components/Banner/types'
import {
  companyFiltersConfig,
  companySortConfig,
  profileFiltersConfig,
  profileSortConfig
} from '../../components/DirectoryList/config'
import { eventsFiltersConfig, eventsSortConfig } from '../../components/EventsResults/config'
import { offersFiltersConfig, offersSortConfig } from '../../components/OffersResults/config'
import { postsFiltersConfig } from '../../components/PostsResults/config'
import { relationsFiltersConfig } from '../../components/RelationsResults/config'
import { articlesFiltersConfig } from '../../components/ArticleFragment/config'
import { searchTypes } from '../../components/Search/types'
import app from '../../config/app'
import Share from '../../data/models/Share'
import { certificationType } from '../../graphql/enums/CertificationType'
import { directoryType, directoryTypeArray } from '../../graphql/enums/DirectoryType'
import { offerSource } from '../../graphql/enums/offerSource'
import Page from '../../graphql/query/Page/index'
import { blocksTransformer } from '../../helpers/BlocksHelpers'
import {
  getFilterValues,
  getFilterValuesFormQueryParams,
  getFilterValuesFromQueryString,
  getSlugFiltersRegex,
  getSortSlugFromValue,
  getSortValueFromQueryParams,
  parseQueryParams
} from '../../helpers/FilterHelpers'
import { pageJsonLd } from '../../helpers/JsonLdHelpers'
import { assetUrl } from '../../helpers/UrlHelpers'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions, pageType, resourceType, selectors as AppSelectors } from '../../redux/app/redux'
import { regexOffersFilters, transformOffersSlugFilters } from '../../helpers/OffersFiltersHelpers'
import { regexPostsFilters, transformPostsSlugFilters } from '../../helpers/PostsFiltersHelpers'
import { regexEventsFilters, transformEventsSlugFilters } from '../../helpers/EventsFiltersHelpers'
import { regexRelationsFilters, transformRelationsSlugFilters, getAllValue } from '../../helpers/RelationsFiltersHelpers'
import { regexArticlesFilters, transformArticlesSlugFilters } from '../../helpers/ArticlesFiltersHelpers'
import {
  regexDirectoryFilters,
  transformDirectorySlugFilters
} from '../../helpers/DirectoryFiltersHelpers'
import LinkedinSagas from '../../redux/linkedin/sagas'
import routes, { Router } from '../../routes'
import i18n from '../../i18n'

import { actions as PageActions } from './redux'


export default class PageSagas {

  static* init({ asPath, query, res }) {
    // linkedIn redirect ?
    if (query?.code && query?.state) {
      yield call(LinkedinSagas.init, { query })
    }

    return yield call(PageSagas.loadPage, query, asPath, res)
  }

  // eslint-disable-next-line complexity
  static* loadPage(query, asPath, res) {
    const { slug = '/', page = 1 } = query

    yield put(showLoading())
    const errors = []
    const pages = yield select(AppSelectors.pages)

    const seoParams = {}

    const params = {
      slug,
      page,
      order: {
        field: 'id',
        order: 'DESC',
      },
      offerFilters: {
        only_cci: true,
      },
      companyFilters: {},
      profileFilters: {},
      eventFilters: {},
      postFilters: {},
      relationFilters: {},
      articleFilters: {},
      directoryType: directoryType.COMPANY,
      sortValue: null,
      filterValues: null,
      filterConfig: null,
      sortConfig: null,
    }

    const newParams = parseQueryParams(asPath, slug)

    //
    // DIRECTORY
    //
    if (pages
      && pages[pageType.DIRECTORY]
      && asPath.indexOf(Router.getRouteUrl(routes.page, { slug: pages[pageType.DIRECTORY]?.link })) === 0) {
      if (newParams?.type && directoryTypeArray.includes(newParams.type)) {
        params.directoryType = newParams.type
      }
      const companyFunctions = yield select(AppSelectors.companyFunctions)
      const certifications = yield select(AppSelectors.certifications)
      const sectors = yield select(AppSelectors.sectors)

      let domains = sectors?.find((s) => s.id === newParams.sector)?.domains

      const t = (str) => i18n.t(str)

      const rseCertifications = certifications?.filter((c) => c.type === certificationType.CERTIFICATION_TYPE_RSE)

      let companyConfig = companyFiltersConfig(rseCertifications, sectors, domains, t)
      const profileConfig = profileFiltersConfig(companyFunctions, t)

      let isCompany = params.directoryType === directoryType.COMPANY

      let filterConfig = isCompany ? companyConfig : profileConfig

      let slugFiltersList = filterConfig?.filter((f) => f.isPath)?.map((f) => f.slug)

      let slugFilters = transformDirectorySlugFilters(params.slug, isCompany)

      if (slugFilters.sector) {
        domains = sectors?.find((s) => s.id === slugFilters.sector)?.domains
        companyConfig = companyFiltersConfig(rseCertifications, sectors, domains, t)
      }

      isCompany = slugFilters.type !== undefined ? (slugFilters.type === directoryType.COMPANY) : true
      params.directoryType = isCompany ? directoryType.COMPANY : directoryType.PROFILE

      filterConfig = isCompany ? companyConfig : profileConfig

      slugFiltersList = filterConfig?.filter((f) => f.isPath)?.map((f) => f.slug)
      slugFilters = transformDirectorySlugFilters(params.slug, isCompany)

      const regex = getSlugFiltersRegex(slugFiltersList)

      params.filterConfig = filterConfig

      params.companyFilters = {
        ...getFilterValuesFormQueryParams(newParams, companyConfig),
        ...getFilterValuesFromQueryString(slugFilters, companyConfig),
      }
      params.profileFilters = {
        ...getFilterValuesFormQueryParams(newParams, profileConfig),
        ...getFilterValuesFromQueryString(slugFilters, profileConfig),
      }
      params.sortConfig = (isCompany ? companySortConfig : profileSortConfig)
      params.order = getSortValueFromQueryParams(newParams, params.sortConfig)

      delete params.companyFilters.type
      delete params.profileFilters.type

      params.filterValues = getFilterValues({ ...newParams, ...slugFilters }, params.filterConfig)
      params.sortValue = getSortSlugFromValue(params.order, params.sortConfig)

      if (newParams.s && newParams.s !== '') {
        params.companyFilters = {
          ...params.companyFilters,
          keyword: newParams.s,
        }
        params.profileFilters = {
          ...params.profileFilters,
          keyword: newParams.s,
        }
      }

      params.slug = params.slug.replace(regexDirectoryFilters(isCompany), '').replace(regex, '')
    }

    //
    // EVENTS
    //
    if (pages
      && pages[pageType.EVENTS]
      && asPath.indexOf(Router.getRouteUrl(routes.page, { slug: pages[pageType.EVENTS]?.link })) === 0) {
      const filterConfig = eventsFiltersConfig

      const slugFiltersList = filterConfig?.filter((f) => f.isPath)?.map((f) => f.slug)
      const slugFilters = transformEventsSlugFilters(params.slug)
      const regex = getSlugFiltersRegex(slugFiltersList)

      params.eventFilters = {
        ...getFilterValuesFormQueryParams(newParams, filterConfig),
        ...getFilterValuesFromQueryString(slugFilters, filterConfig),
      }
      params.order = getSortValueFromQueryParams(newParams, eventsSortConfig)

      params.filterConfig = filterConfig
      params.sortConfig = eventsSortConfig

      params.filterValues = getFilterValues({ ...newParams, ...slugFilters }, filterConfig)
      params.sortValue = getSortSlugFromValue(params.order, eventsSortConfig)

      params.slug = params.slug.replace(regexEventsFilters, '').replace(regex, '')
    }

    //
    // POSTS
    //
    if (pages
      && pages[pageType.POSTS]
      && asPath.indexOf(Router.getRouteUrl(routes.page, { slug: pages[pageType.POSTS]?.link })) === 0) {
      const filterConfig = postsFiltersConfig

      const slugFiltersList = filterConfig?.filter((f) => f.isPath)?.map((f) => f.slug)
      const slugFilters = transformPostsSlugFilters(params.slug)
      const regex = getSlugFiltersRegex(slugFiltersList)

      params.postFilters = {
        ...getFilterValuesFormQueryParams(newParams, filterConfig),
        ...getFilterValuesFromQueryString(slugFilters, filterConfig),
      }

      params.filterConfig = filterConfig

      params.filterValues = getFilterValues({ ...newParams, ...slugFilters }, filterConfig)

      params.slug = params.slug.replace(regexPostsFilters, '').replace(regex, '')
    }

    //
    // ARTICLES
    //
    if (pages
      && pages[pageType.ARTICLES]
      && asPath.indexOf(Router.getRouteUrl(routes.page, { slug: pages[pageType.ARTICLES]?.link })) === 0) {
      const articleCategories = yield select(AppSelectors.articleCategories)
      const filterConfig = articlesFiltersConfig(articleCategories)

      const slugFiltersList = filterConfig?.filter((f) => f.isPath)?.map((f) => f.slug)
      const slugFilters = transformArticlesSlugFilters(params.slug)
      const regex = getSlugFiltersRegex(slugFiltersList)

      params.articleFilters = {
        ...getFilterValuesFormQueryParams(newParams, filterConfig),
        ...getFilterValuesFromQueryString(slugFilters, filterConfig),
      }

      params.filterConfig = filterConfig

      params.filterValues = getFilterValues({ ...newParams, ...slugFilters }, filterConfig)

      params.slug = params.slug.replace(regexArticlesFilters, '').replace(regex, '')
    }

    //
    // OFFERS
    //
    if (pages
      && pages[pageType.OFFERS]
      && asPath.indexOf(Router.getRouteUrl(routes.page, { slug: pages[pageType.OFFERS]?.link })) === 0) {
      const slugFilters = transformOffersSlugFilters(params.slug)
      const categories = yield select(AppSelectors.categories)
      const t = (str) => i18n.t(str)
      // const filterConfig = offersFiltersConfig(t, categories)
      const categoriesWithoutProject = categories.filter((x) => x.offerType === null)
      const projectCategories = categories.filter((x) => x.offerType === 'project')

      const filterConfig = offersFiltersConfig(t, slugFilters.type === 'project' ? projectCategories : categoriesWithoutProject)
      const slugFiltersList = filterConfig?.filter((f) => f.isPath)?.map((f) => f.slug)
      const regex = getSlugFiltersRegex(slugFiltersList)

      params.offerFilters = {
        ...getFilterValuesFormQueryParams(newParams, filterConfig),
        ...getFilterValuesFromQueryString(slugFilters, filterConfig),
      }
      if (params.offerFilters.source) {
        if (params.offerFilters.source === offerSource.CCI) {
          params.offerFilters.only_cci = true
        }
        params.offerFilters.source = undefined
      }
      params.order = getSortValueFromQueryParams(newParams, offersSortConfig)

      params.filterConfig = filterConfig
      params.sortConfig = offersSortConfig

      params.filterValues = getFilterValues({ ...newParams, ...slugFilters }, filterConfig)
      params.sortValue = getSortSlugFromValue(params.order, offersSortConfig)

      if (newParams.s && newParams.s !== '') {
        params.offerFilters.keyword = newParams.s
      }

      if (newParams.source && newParams.source === offerSource.CCI) {
        params.offerFilters.only_cci = true
      }

      params.slug = params.slug.replace(regexOffersFilters, '').replace(regex, '')
    }

    //
    // RELATIONS
    //

    if (pages
      && pages[pageType.RELATIONS]
      && asPath.indexOf(Router.getRouteUrl(routes.page, { slug: pages[pageType.RELATIONS]?.link })) === 0) {
      const sectors = yield select(AppSelectors.sectors)
      const t = (str) => i18n.t(str)
      const filterConfig = relationsFiltersConfig(t, sectors[0], sectors[1], sectors[2], sectors[3])

      const slugFiltersList = filterConfig?.filter((f) => f.isPath)?.map((f) => f.slug)
      const slugFilters = transformRelationsSlugFilters(params.slug)
      const regex = getSlugFiltersRegex(slugFiltersList)

      params.relationFilters = { domains: Object.entries(newParams).map(([key, value]) => (value ? value.split(',') : [])).flat(2) }

      params.filterConfig = filterConfig

      params.filterValues = getFilterValues({ ...newParams, ...slugFilters }, filterConfig)

      params.filterValues = params.filterValues.map((f) => {
        if (f.value.length === filterConfig.find((c) => c.slug === f.name)?.options?.length) {
          return { ...f, value: [getAllValue(t)] }
        }
        return f
      })

      params.slug = params.slug.replace(regexRelationsFilters, '').replace(regex, '')
      params.order = {
        field: 'percentage',
        order: 'DESC',
      }
    }

    // Remove /p[0-9] in slug
    if (params.slug.match(/\/p([0-9]+)/)) {
      params.slug = params.slug.replace(/\/p([0-9]+)/, '')
    }

    //
    // QUERY
    //
    const result = yield call(ApiSagas.query, Page.byFullSlug, { ...params, order: [params.order] })

    // Check if errors
    if (result.errors && result.errors.length) {
      console.log('Page: Errors', result.errors)
      result.errors.map((error) => errors.push(error))
    }
    // eslint-disable-next-line
    const returnedData = yield call(PageSagas.transformData, result.data?.pageByFullSlug, params)
    const pagination = returnedData?.blocks?.find((b) => b.pagination)?.pagination

    if (pagination) {
      const p = pagination.currentPage

      seoParams.url = `${app.APP_URL}/${slug}${p > 1 ? `/p${p}` : ''}`
      if (pagination.currentPage - 1 > 0) {
        seoParams.prev = `${app.APP_URL}/${slug}${(p - 1) > 1 ? `/p${p - 1}` : ''}`
      }
      if (pagination.currentPage + 1 < pagination.totalPage) {
        seoParams.next = `${app.APP_URL}/${slug}${(p + 1) > 1 ? `/p${p + 1}` : ''}`
      }
    }

    const bannerBlockConfig = yield call(PageSagas.transformBannerBlock, returnedData, seoParams)
    const searchBlockConfig = yield call(PageSagas.transformSearchBlock, returnedData)
    const breadCrumb = yield call(PageSagas.transformBreadcrumb, returnedData)
    const jsonld = yield call(PageSagas.transformJsonld, returnedData, seoParams)
    const seo = yield call(PageSagas.transformSeo, returnedData, seoParams)

    yield all([
      put(PageActions.setData(returnedData)),
      put(AppActions.setBannerBlock(bannerBlockConfig)),
      put(AppActions.setSearchBlock(searchBlockConfig)),
      put(AppActions.setBreadcrumb(breadCrumb)),
      put(AppActions.setResource(resourceType.PAGE, returnedData?.id)),
      put(AppActions.setJsonld(jsonld)),
      put(AppActions.setSeo(seo)),
    ])

    yield all([
      put(PageActions.setLoaded(true)),
      put(hideLoading()),
    ])

    // Check errors
    if (errors && errors.length >= 1) {
      console.log('[PAGE] 404 error loading data', errors)
      yield put(hideLoading())
      return { error: 404 }
    }

    if (returnedData === null) {
      console.log('[PAGE] 404 error loading data', result)
      yield put(hideLoading())
      return { error: 404 }
    }
  }

  static* transformData(data, params) {
    if (data && data.blocks) {
      const pages = yield select(AppSelectors.pages)

      return {
        ...data,
        blocks: blocksTransformer(data.blocks, { ...params, pages }),
      }
    }

    return data
  }

  static transformBannerBlock(data, { h1, subtitle }) {
    if (data && data.has_header) {
      return {
        format: bannerTypes[data.header_type],
        title: h1 || data.header_title,
        subtitle: subtitle || data.header_subtitle,
        image: data.header_image,
        ...data.header_cta_url && data.header_cta_label && {
          ctaLabel: data.header_cta_label,
          ctaLink: data.header_cta_url,
          ctaType: data.header_cta_type,
        },
      }
    }

    return {}
  }

  static transformSearchBlock(data) {
    if (data && data.has_search_bar) {
      return {
        title: data.search_bar_title,
        type: searchTypes[data.search_bar_type],
        stats: data.search_bar_stat ? {
          companies: {
            value: data.stat?.companies_count,
          },
          events: {
            value: data.stat?.events_count,
          },
          posts: {
            value: data.stat?.posts_count,
          },
          users: {
            value: data.stat?.candidates_count,
          },
          offers: {
            value: data.stat?.offers_count,
          },
        } : null,
      }
    }

    return {}
  }

  static transformBreadcrumb(data) {
    if (data && data.breadcrumb && data.breadcrumb.length >= 1) {
      return data.breadcrumb.map((item) => ({
        label: item.title,
        ...!item.is_current && {
          link: item?.link === '' ? '/' : item?.link,
        },
      }))
    }

    return []
  }

  static transformJsonld(data, { title }) {
    if (data) {
      if (title) {
        return pageJsonLd({ ...data, share: { ...data.share, title } })
      }
      return pageJsonLd(data)
    }

    return {}
  }

  static transformSeo(data, params) {
    if (data && data.share) {
      const transformedShare = Share(data.share)

      return {
        ...transformedShare,
        ...params,
        url: params.url || `${app.APP_URL}${data.full_slug}`,
        image: transformedShare.image ? assetUrl(transformedShare.image, { width: 1024 }) : '',
        noindex: false,
      }
    }

    return {}
  }

  static* loop() {
    yield all([
      //
    ])
  }

}
