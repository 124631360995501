import gql from 'graphql-tag'

import { TimesStampsFragment } from './TimesStampsFragment'


export const EventCardFragment = {
  fragment: gql`
      fragment eventCard on Event {
          id
          name
          slug
          full_slug
          type
          provider
          place
          begin_at
          end_at
          link
          has_page
          background_image
          cost
          second_cost
          introduction
          description
          program
          ...timesStampsFields
          location {
              name
              address
          }
      }
      
      # Fragments
      ${TimesStampsFragment.fragment}
  `,
}
