import gql from 'graphql-tag'


export const linkedInProfile = gql`
  query linkedin($code: String!, $redirectUri: String!){
    linkedInProfile(
      authorization_code: $code
      redirect_uri: $redirectUri
    ) {
      first_name
      last_name
      email
    }
  }
`
