import gql from 'graphql-tag'


const HighlightedEventBlockFragment = gql`
    fragment HighlightedEventBlockFragment on BlockType {
        ... on HighlightedEventBlock {
            highlightedEvent {
                ...eventCard
            }
        }
    }
`

export default HighlightedEventBlockFragment
