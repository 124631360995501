import gql from 'graphql-tag'


const ArticleContainerBlockFragment = gql`
    fragment ArticleContainerBlockFragment on BlockType{
        ... on ArticleContainerBlock {
            text
            articlesByFilters(
                first: 12,
                page: $page,
                filters: $articleFilters
            ) {
                paginatorInfo {
                    ...pagination
                }
                data {
                    title
                    slug
                    category {
                        name
                        theme
                        bgColor
                    }
                    description
                    picture
                    is_highlighted
                }
            }
        }
    }
`

export default ArticleContainerBlockFragment
