import gql from 'graphql-tag'


export const UpdateFavoriteUser = gql`
    mutation updateFavoriteUser(
        $favoriteUser: UpdateFavoriteUserRelation!
    ) {
      updateFavoriteUser(
        favoriteUser: $favoriteUser
        ) {
            favoritesUsers {
              id
              first_name
              last_name
              companyFunction {
                name
              }
              company {
                name
              }
              avatar
              current_needs
            }
        }
    }
`
