import gql from 'graphql-tag'


const DirectoryBlockFragment = gql`
    fragment DirectoryBlockFragment on BlockType{
      ... on DirectoryBlock {
          companiesTitle
          companiesSubtitle
          companiesByFilters(
              first: 18,
              type: $directoryType,
              filters: $companyFilters,
              orderBy: $order,
              page: $page
          ){
            data{
              id
              name
              slug
              full_slug
              logo
              domains{
                  name
                  slug
                  color
                  sector{
                      name
                      slug
                  }
              }
              city
              is_premium
              is_ambassador
              hasCertifications
              certifications {
                  id
                  name
                  logo
                  type
              }
              is_ogcn
            }
            paginatorInfo{
                ...pagination
            }
          }
          profilesTitle
          profilesSubtitle
          profilesByFilters(
              first: 18,
              type: $directoryType,
              filters: $profileFilters,
              orderBy: $order,
              page: $page
          ){
            data{
              id
              first_name
              last_name
              avatar
              slug
              companyFunction {
                id
                name
              }
              company {
                id
                name
                slug
                full_slug
                is_ogcn
              }
            }
            paginatorInfo{
              ...pagination
            }
          }
      }
    }
`

export default DirectoryBlockFragment
