const filtersCompanySlugTypes = [
  'type',
  'company-type',
  'sector',
  'domains',
  'certifications',
  'turnovers',
]

const filtersProfileSlugTypes = [
  'type',
  'role',
]

const getFilterCompanySlugRightField = {
  type: 'type',
  'company-type': 'company-type',
  sector: 'sector',
  domains: 'domains',
  certifications: 'certifications',
  turnovers: 'turnovers',
}

const getFilterProfileSlugRightField = {
  type: 'type',
  role: 'role',
}

export const regexDirectoryFilters = (isCompany) => {
  const types = isCompany ? filtersCompanySlugTypes : filtersProfileSlugTypes

  return new RegExp(`(?:\\/(?:(?:(${types.join('|')})-([\\w\\d-]+))))`, 'g')
}

export const transformDirectorySlugFilters = (asPath, isCompany) => {
  let matches
  const slugFilters = {}

  const regex = regexDirectoryFilters(isCompany)

  while ((matches = regex.exec(asPath)) !== null) {
    const rightFields = isCompany ? getFilterCompanySlugRightField : getFilterProfileSlugRightField
    const property = rightFields[matches[1]]

    if (!Object.prototype.hasOwnProperty.call(slugFilters, property)) {
      slugFilters[property] = matches[2]
    } else {
      if (!Array.isArray(slugFilters[property])) {
        slugFilters[property] = [slugFilters[property]]
      }
      slugFilters[property].push(matches[2])
    }
  }
  return slugFilters
}
