import gql from 'graphql-tag'


const TestimonyContainerBlockFragment = gql`
    fragment TestimonyContainerBlockFragment on BlockType{
      ... on TestimonyContainerBlock {
          title
          testimonies {
              image
              name
              quote
              job
              media_type
              media
          }
      }
    }
`

export default TestimonyContainerBlockFragment
