import removeMD from 'remove-markdown'

import app from '../config/app'
import Offer from '../data/models/Offer'
import routes, { Router } from '../routes'

import { assetUrl } from './UrlHelpers'


export const debugJsonLd = (props) => console.log('DEBUG:JSONLD', props) || {}

export const pageJsonLd = ({ share, header_title, header_subtitle, created_at, updated_at, full_slug }) => ({
  '@context': 'http://schema.org',
  '@type': 'WebPage',
  name: share?.title,
  description: share?.description,
  image: share?.picture ? assetUrl(share.picture, { width: 1024 }) : '',
  url: `${app.APP_URL}${full_slug}`,
  headline: `${header_title || ''} ${header_subtitle || ''}`,
  dateCreated: created_at,
  dateModified: updated_at,
  author: 'EDRH Sud',
  inLanguage: 'fr-FR',
})

export const offerJsonLd = ({
  share,
  label,
  published_at,
  contract,
  experience,
  location,
  provider,
  company,
  skills,
  reference,
  full_slug,
}) => ({
  '@context': 'http://schema.org',
  '@type': 'JobPosting',
  datePosted: published_at,
  employmentType: Offer.contractTypes[contract],
  experienceRequirements: experience,
  hiringOrganization: {
    '@type': 'Organization',
    name: provider?.name,
    logo: provider?.logo ? assetUrl(provider.logo, { width: 1024 }) : '',
  },
  jobLocation: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      streetAddress: location?.address,
      addressLocality: location?.city,
      postalCode: location?.zip_code,
      addressCountry: 'FR',
    },
  },
  industry: company?.title,
  skills: skills?.reduce((acc, val) => `${acc}${val.name} `, ''),
  title: label,
  name: share?.title,
  description: share?.description,
  image: share?.picture ? assetUrl(share.picture, { width: 1024 }) : '',
  url: reference && `${app.APP_URL}${Router.getRouteUrl(routes.offer, { slug: full_slug })}`,
  headline: label,
  dateCreated: published_at,
  author: 'EDRH Sud',
  inLanguage: 'fr-FR',
})

export const companyJsonLd = ({ name, logo, location, full_slug }) => ({
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name,
  legalName: name,
  Brand: {
    name,
    logo: logo ? assetUrl(logo, { width: 1024 }) : '',
  },
  url: `${app.APP_URL}${Router.getRouteUrl(routes.company, { slug: full_slug })}`,
  logo: logo ? assetUrl(logo, { width: 1024 }) : '',
  address: {
    '@type': 'PostalAddress',
    streetAddress: location?.address,
    addressLocality: location?.city,
    postalCode: location?.zip_code,
  },
  inLanguage: 'fr-FR',
})

export const profileJsonLd = ({ profile, avatar, slug }) => ({
  '@context': 'http://schema.org/',
  '@type': 'Person',
  name: profile?.fullName,
  url: `${app.APP_URL}${Router.getRouteUrl(routes.profile, { slug })}`,
  avatar: avatar ? assetUrl(avatar, { width: 1024 }) : '',
  inLanguage: 'fr-FR',
})

export const eventJsonLd = ({ name, description, begin_at, end_at, event, place, location, slug }) => ({
  '@context': 'http://schema.org',
  '@type': 'Event',
  name,
  startDate: new Date(begin_at).toISOString(),
  endDate: new Date(end_at).toISOString(),
  eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
  eventStatus: 'https://schema.org/EventScheduled',
  location: {
    '@type': 'Place',
    name: place,
    address: {
      '@type': 'PostalAddress',
      streetAddress: location?.address,
      addressLocality: location?.city,
      postalCode: location?.zip_code,
    },
  },
  image: event.cover ? assetUrl(event.cover, { width: 1024 }) : '',
  description: removeMD(description),
  url: `${app.APP_URL}${Router.getRouteUrl(routes.event, { slug })}`,
  inLanguage: 'fr-FR',
})

export const articleJsonLd = ({ share, title, description, slug, published_at, created_at, updated_at }) => ({
  '@context': 'http://schema.org',
  '@type': 'Article',
  headline: title,
  articleBody: description,
  image: (share && share.picture) ? assetUrl(share.picture, { width: 1024 }) : '',
  url: `${app.APP_URL}${Router.getRouteUrl(routes.post, { slug })}`,
  dateCreated: created_at,
  dateModified: updated_at,
  datePublished: published_at || created_at,
  author: 'EDRH Sud',
  inLanguage: 'fr-FR',
})
