import gql from 'graphql-tag'


const OfferListBlockFragment = gql`
    fragment OfferListBlockFragment on BlockType {
        ... on OfferListBlock {
            text
            offersByFilters(first: 8, orderBy: $order, filters: $offerFilters, page: $page) {
                paginatorInfo {
                    ...pagination
                }
                data {
                    ...offerCard
                }
            }
        }
    }
`

export default OfferListBlockFragment
