import gql from 'graphql-tag'


const PushContentBlockFragment = gql`
    fragment PushContentBlockFragment on BlockType{
      ... on PushBlock {
          background_image
          header_title
          title
          description
          cta_title
          cta_link
      }
    }
`

export default PushContentBlockFragment
