import { query } from '../../../helpers/GraphqlHelpers'

import { GetXp } from './GetXp'
import { UpdateXp } from './UpdateXp'
import { GetSkills } from './GetSkills'
import { UpdateSkills } from './UpdateSkills'
import { GetCandidateOpportunities } from './GetCandidateOpportunities'
import { GetAlerts } from './GetAlerts'
import { MarkNotificationAsRead } from './MarkNotificationAsRead'
import { deleteAccount } from './DeleteAccount'
import { linkedInProfile } from './LinkedinProfile'
import { UpdateFavoriteUser } from './UpdateUserFavorite'
import { usersByAutocomplete } from './ByAutocomplete'


export default {
  markNotificationAsRead: (variables, headers = {}) => query(MarkNotificationAsRead, variables, headers),
  getAlerts: (variables, headers = {}) => query(GetAlerts, variables, headers),
  getXp: (variables, headers = {}) => query(GetXp, variables, headers),
  updateXp: (variables, headers = {}) => query(UpdateXp, variables, headers),
  getSkills: (variables, headers = {}) => query(GetSkills, variables, headers),
  updateSkills: (variables, headers = {}) => query(UpdateSkills, variables, headers),
  GetCandidateOpportunities: (variables, headers = {}) => query(GetCandidateOpportunities, variables, headers),
  deleteAccount: (variables, headers = {}) => query(deleteAccount, variables, headers),
  linkedInProfile: (variables, headers = {}) => query(linkedInProfile, variables, headers),
  updateUserFavorite: (variables, headers = {}) => query(UpdateFavoriteUser, variables, headers),
  byAutocomplete: (variables, headers = {}) => query(usersByAutocomplete, variables, headers),
}
