import gql from 'graphql-tag'


export const ShareFragment = {
  fragment: gql`
    fragment share on Share {
        title
        picture
        description
    }
  `,
}
