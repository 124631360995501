import gql from 'graphql-tag'


const OfferPublicBlockFragment = gql`
    fragment OfferPublicBlockFragment on BlockType {
      ... on OfferPublicBlock {
          title
          introduction_before_blue
          introduction_blue
          introduction_after_blue
          premium_title
          premium_text
          premium_cta_title
          premium_linkable {
              __typename
              ... on Route {
                  name
                  slug
              }
              ... on Page {
                  title
                  full_slug
              }
          }
          premium_url
          title_text_containers {
              title_texts {
                  title
                  text
              }
          }
      }
    }
`

export default OfferPublicBlockFragment
